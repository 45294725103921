import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"

export default class extends Controller {
  connect(){
    const loader = new Loader({
      apiKey: "AIzaSyDghm-dhFaTfXHmzcETAQDEesKViDqzEHI",
      version: "weekly",
      libraries: ["places"]
    });

    loader.load().then(() => {
      const event = new Event('google-maps-callback', {
        bubbles: true,
        cancelable: true,
      })
      window.dispatchEvent(event);
    });
  }
}
