import { Controller } from "@hotwired/stimulus"
import KTMenu from "../../../_keenthemes/src/js/components/menu";

// Connects to data-controller="kt-menu"
export default class extends Controller {
  static targets = [ "container" ];
  
  connect() {
    if (this.hasContainerTarget) {
      if (KTMenu.getInstance(this.containerTarget)) {
        console.log("Already has menu")
        return;
      }

      this.menu = new KTMenu(this.containerTarget);
    }
  }

  disconnect() {
    if (this.menu) {
      this.menu.destroy();
    }
  }

  click(e) {
    if (this.menu) {
      this.menu.click(e.currentTarget, e);
    }
  }

  link(e) {
    if (this.menu) {
      this.menu.link(e.currentTarget, e);
    }
  }

  dismiss(e) {
    if (this.menu) {
      this.menu.dismiss(e.currentTarget, e);
    }
  }

  mouseover(e) {
    if (this.menu) {
      this.menu.mouseover(e.currentTarget, e);
    }
  }

  mouseout(e) {
    if (this.menu) {
      this.menu.mouseout(e.currentTarget, e);
    }
  }

  // TODO: refactor
  // copy from _keenthemes/src/components/menu.js#1003
  documentClick(e) {
    var items = document.querySelectorAll('.show.menu-dropdown[data-kt-menu-trigger]:not([data-kt-menu-static="true"])');
    var menu;
    var item;
    var sub;
    var menuObj;

    if ( items && items.length > 0 ) {
        for ( var i = 0, len = items.length; i < len; i++ ) {
            item = items[i];
            menuObj = KTMenu.getInstance(item);

            if (menuObj && menuObj.getItemSubType(item) === 'dropdown') {
                menu = menuObj.getElement();
                sub = menuObj.getItemSubElement(item);

                if ( item === e.target || item.contains(e.target) ) {
                    continue;
                }
                
                if ( sub === e.target || sub.contains(e.target) ) {
                    continue;
                }
                    
                menuObj.hide(item);
            }
        }
    }
  }

  // copy from _keenthemes/src/components/menu.js#1079
  windowResize(e) {
    var menu;
    var timer;

    KTUtil.throttle(timer, function() {
        // Locate and update Offcanvas instances on window resize
        var elements = document.querySelectorAll('[data-kt-menu="true"]');

        if ( elements && elements.length > 0 ) {
            for (var i = 0, len = elements.length; i < len; i++) {
                menu = KTMenu.getInstance(elements[i]);
                if (menu) {
                    menu.update();
                }
            }
        }
    }, 200);
  }
}
