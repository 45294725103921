import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "table", "search" ];

	connect(){
    this.q = { mailbox: window.location.hash.replace(/^\#/, '') }

    this.dataTable = $(this.tableTarget).DataTable({
      searchDelay: 500,
      processing: true,
      serverSide: true,
      order: [[4, 'desc']],
      select: {
        style: 'multi',
        selector: 'td:first-child input[type="checkbox"]',
        className: 'row-selected'
      },
      ajax: {
        url: '/gmail/messages.json',
        data: d => { 
          var q = {
            ...this.q,
            search_cont: d.search.value,
            s: `${d.columns[d.order[0].column].data} ${d.order[0].dir}`,
          };

          return {
            draw: d.draw,
            start: d.start,
            length: d.length,
            q: q
          };
        }
      },
      columns: [
        { data: 'id' },
        { data: 'labels' },
        { data: 'author' },
        { data: 'snippet' },
        { data: 'gmail_mailbox_email' },
        { data: 'gmail_internal_date' }
      ],
      columnDefs: [
        {
          targets: 0,
          className: 'ps-9',
          orderable: false,
          render: function (data) {
            return `
                            <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                                <input class="form-check-input" type="checkbox" value="${data}" />
                            </div>`;
          }
        },
        {
          targets: 1,
          className: "min-w-80px",
          orderable: true,
          render: function (data) {
            return `
                    <!--begin::Star-->
                    <a href="#" class="btn btn-icon ${(data.indexOf('STARRED') > -1) ? 'btn-color-primary btn-active-color-gray-400' : 'btn-color-gray-400 btn-active-color-primary'} w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Star" data-bs-original-title="Star" data-kt-initialized="1">
                        <!--begin::Svg Icon | path: icons/duotune/general/gen029.svg-->
<span class="svg-icon svg-icon-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z" fill="currentColor"></path>
</svg>
</span>
<!--end::Svg Icon-->                    </a>
                    <!--end::Star-->

                    <!--begin::Important-->
                    <a href="#" class="btn btn-icon ${(data.indexOf('IMPORTANT') > -1) ? 'btn-color-primary btn-active-color-gray-400' : 'btn-color-gray-400 btn-active-color-primary'} w-35px h-35px" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Mark as important" data-bs-original-title="Mark as important" data-kt-initialized="1">
                        <!--begin::Svg Icon | path: icons/duotune/general/gen056.svg-->
<span class="svg-icon svg-icon-4 mt-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0077 19.2901L12.9293 17.5311C12.3487 17.1993 11.6407 17.1796 11.0426 17.4787L6.89443 19.5528C5.56462 20.2177 4 19.2507 4 17.7639V5C4 3.89543 4.89543 3 6 3H17C18.1046 3 19 3.89543 19 5V17.5536C19 19.0893 17.341 20.052 16.0077 19.2901Z" fill="currentColor"></path>
</svg>
</span>
<!--end::Svg Icon-->                    </a>
                    <!--end::Important-->
                `;
          }
        },
        {
          targets: 2,
          className: "w-150px w-md-175px",
          orderable: true
        },
        {
          targets: 3
        },
        {
          targets: -1,
          className: "w-100px text-end fs-7 pe-9",
          orderable: true
        }
      ]
    });

    this.dataTable.on('draw', () => {
      //initToggleToolbar();
      //toggleToolbars();
      //handleDeleteRows();
    });
  }

  disconnect() {
    this.dataTable.destroy()
    this.dataTable = undefined
  }

  search() {
    this.dataTable.search(this.searchTarget.value).draw();
  }

  selectMailbox(event) {
    event.preventDefault();

    this.q.mailbox = event.params.mailbox;
    this.dataTable.ajax.reload();
  }

  refresh(event) {
    event.preventDefault();

    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/gmail/messages/refresh", {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      } // body data type must match "Content-Type" header
    })
      .then(response => {
        console.log(response)
      })
  }
}
