import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="kt-toggle"
export default class extends Controller {
  connect() {
    this.toggle = new KTToggle(this.element);
  }

  disconnect() {
    this.toggle.destroy();
  }
}
