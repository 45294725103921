import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notification-message"
export default class extends Controller {
  static values = { 
		recordId: String
	}

  connect() {    
  }

  seen() {
    const csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(`/notification_messages/${this.recordIdValue}`, {
      method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ seen_at: new Date().toISOString() }) // body data type must match "Content-Type" header
    })
      .then(response => {
        console.log(response)
      })
  }
}
