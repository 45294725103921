import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sms-code"
export default class extends Controller {
  static targets = [ "digit", "form", "password" ];

  connect() {
    this.digitTargets.forEach((element, index) => {
      element.addEventListener("keyup", () => {
        if (index == this.digitTargets.length - 1) {
          1 === element.value.length && this.digitTargets[index].blur();
          this.submitPassword();
        } else {
          1 === element.value.length && this.digitTargets[index+1].focus();
        }
      });
    });
  }

  submitPassword() {
    // exclude from POST data
    this.digitTargets.forEach((element, index) => {
      element.disabled = true;
    });

    let password = this.digitTargets.map(element => element.value).join('')
    this.passwordTarget.value = password;

    this.formTarget.submit();
  }
}
