import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "table", "periodPicker", "totals", "downloader" ];
  static values = { defaultDate: String };

  transformAjaxData(d) {
    return {
      draw: d.draw,
      start: d.start,
      length: d.length,
      end_of_period: moment(this.periodPicker.selectedDates[0]).format('YYYY-MM-DD')
    };
  }

	connect(){
    this.periodPicker = $(this.periodPickerTarget).flatpickr({
        altInput: true,
        altFormat: "d.m.Y",
        dateFormat: "Y-m-d",
        defaultDate: this.defaultDateValue,
        onChange: (selectedDates, dateStr, instance) => {
          this.dataTable.ajax.reload();
        },
      });

    this.dataTable = $(this.tableTarget).DataTable({
      processing: true,
      serverSide: true,
      pageLength: 10,
      columns: [
        { data: 'number' },
        { data: 'trucks' },
        { data: 'client_title' },
        { data: 'title' },
        { data: 'price' },
        { data: 'payment_deadline_date' },
      ],
      ajax: {
        url: '/dashboard/waiting_payment_trips.json',
        data: d => { return this.transformAjaxData(d) }
      },
    });

    $(this.tableTarget).on('xhr.dt', (e, settings, json, xhr ) => {
      this.totalsTarget.innerHTML = json.totals;
    });
  }

  exportXlsx(ev) {
    ev.preventDefault();

    var queryString = $.param(this.dataTable.ajax.params());

    this.downloaderTarget.setAttribute('src', `/dashboard/waiting_payment_trips.xlsx?${queryString}`);
  }

  disconnect() {
    this.dataTable.destroy()
    this.dataTable = undefined

    this.periodPicker.destroy()
    this.periodPicker = undefined
  }

  clearPeriod() {
    if (this.periodPicker) {
      this.periodPicker.clear();
    }
  }

  beforeStreamRender(event) {
    const fallbackToDefaultActions = event.detail.render;

    event.detail.render = (streamElement) => {
      fallbackToDefaultActions(streamElement);
    }
  }
}
