import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "kind", "purse", "senderPurse", "paymentCategory" ];

	connect() {
		$(this.paymentCategoryTarget).select2({
			ajax: {
				url: '/settings/payment_categories.json',
				data: (params) => {
					return {
            'q[title_cont]': params.term,
            [`q[${this.kind}]`]: true,
						page: params.page
					};
				}
			}
		});

    $(this.paymentCategoryTarget).on('select2:select', (e) => {
      var data = e.params.data;
      console.log(data);

      if (data.purseId) {
        $(this.purseTarget).val(data.purseId).trigger('change');
      }
    });
	}

	kindChanged() {
		const element = this.kindTarget

		if (this.isTransfer) {
			this.senderPurseTarget.classList.remove('d-none');
		} else {
			this.senderPurseTarget.classList.add('d-none');
		}
	}

	get isTransfer() {
		return this.kindTargets.some((element) => { return (element.value == 'transfer' && element.checked) })
	}

	get kind() {
		return this.kindTargets.find((element) => { return element.checked }).value
	}
}
