import { Controller } from "@hotwired/stimulus"
//import $ from 'jquery';
//import Select2 from "select2"

export default class extends Controller {
	static targets = ["truck", "trailer", "driver"];

	connect() {
		$(this.truckTarget).select2({
			ajax: {
				url: '/trucks',
				delay: 250,
				data: (params) => {
					return {
						q: {
							search_cont: params.term
						},
						page: params.page
					};
				}
			}
		});

		if (this.hasTrailerTarget) {
			$(this.trailerTarget).select2();
		}
		
		$(this.driverTarget).select2();

		$(this.truckTarget).on('select2:select', (e) => {
			var data = e.params.data;
			console.log(data);

			if (data.driver) {
				var option = new Option(data.driver.title, data.driver.id, true, true);
				$(this.driverTarget).append(option).trigger('change');
			} else {
				$(this.driverTarget).val(null).trigger('change');
			}

			if (this.hasTrailerTarget) {
				if (data.trailer) {
					var option = new Option(data.trailer.title, data.trailer.id, true, true);
					$(this.trailerTarget).append(option).trigger('change');
				} else {
					$(this.trailerTarget).val(null).trigger('change');
				}
			}
		});
	}
}
