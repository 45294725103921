import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fuel-consumtion-chart"
export default class extends Controller {
  static targets = [ "chart" ];
  static values = { 
    truckId: String
  }

  connect() {
    var height = parseInt(KTUtil.css(this.chartTarget, 'height'));
    var labelColor = KTUtil.getCssVariableValue('--bs-gray-500');
    var borderColor = KTUtil.getCssVariableValue('--bs-border-dashed-color');
    var baseprimaryColor = KTUtil.getCssVariableValue('--bs-primary');
    var lightprimaryColor = KTUtil.getCssVariableValue('--bs-primary');
    var basesuccessColor = KTUtil.getCssVariableValue('--bs-success');
    var lightsuccessColor = KTUtil.getCssVariableValue('--bs-success');

    var options = {
      chart: {
          fontFamily: 'inherit',
          type: 'area',
          height: height,
          toolbar: {
              show: false
          }
      },
      plotOptions: {
      
      },
      legend: {
          show: false
      },
      dataLabels: {
          enabled: false
      },
      fill: {
          type: "gradient",
          gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.4,
              opacityTo: 0.2,
              stops: [15, 120, 100]
          }
      },
      stroke: {
          curve: 'smooth',
          show: true,
          width: 3,
          colors: [baseprimaryColor, basesuccessColor]
      },
      xaxis: {
          type: "datetime",
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false
          },
          tickAmount: 6,
          labels: {
              rotate: 0,
              rotateAlways: true,
              style: {
                  colors: labelColor,
                  fontSize: '12px'
              }
          },
          crosshairs: {
              position: 'front',
              stroke: {
                  color: [baseprimaryColor, basesuccessColor],
                  width: 1,
                  dashArray: 3
              }
          },
          tooltip: {
              enabled: true,
              formatter: undefined,
              offsetY: 0,
              style: {
                  fontSize: '12px'
              }
          }
      },
      yaxis: {
          tickAmount: 6,
          labels: {
              style: {
                  colors: labelColor,
                  fontSize: '12px'
              } 
          }
      },
      states: {
          normal: {
              filter: {
                  type: 'none',
                  value: 0
              }
          },
          hover: {
              filter: {
                  type: 'none',
                  value: 0
              }
          },
          active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                  type: 'none',
                  value: 0
              }
          }
      },
      tooltip: {
          style: {
              fontSize: '12px'
          } 
      },
      colors: [lightprimaryColor, lightsuccessColor],
      grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
              lines: {
                  show: true
              }
          }
      },
      markers: {
          strokeColor: [baseprimaryColor, basesuccessColor],
          strokeWidth: 3
      }
    };

    fetch(`/trucks/${this.truckIdValue}/fuel_consumtion_chart.json`).then(response => {
      response.json().then(data => {
        options.series = data.series;
        
        this.chart = new ApexCharts(this.chartTarget, options);

        this.chart.render();
        this.rendered = true;
      })
    })

    // Set timeout to properly get the parent elements width
    //setTimeout(function() {
      
    //}, 200);  
  }

  disconnect() {
    if (this.rendered) {
      this.chart.destroy();
    }
  }
}
