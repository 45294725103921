import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
	static targets = [ "table", "footer", "column", "search", "periodPicker", "filter", "toolbarSelectFilter", "downloader", "checkbox", "allCheckboxes", "selectedCount", "toolbarSelected", "toolbarBase", "filtersToggle" ];
  static values = { 
    ajaxUrl: String,
    exportUrl: String,
    batchDeleteUrl: String, 
    periodColumn: String,
    searchParam: { type: String, default: 'search_cont' }
  };

  transformAjaxData(d) {
    var q = {
      [this.searchParamValue]: d.search.value,
      s: `${this.columnSortings[d.columns[d.order[0].column].data]} ${d.order[0].dir}`
    };

    let filtersCount = 0;

    this.filterTargets.forEach(filterEl => {
      if (filterEl.value) {
        q[filterEl.name] = filterEl.value 
        filtersCount = filtersCount + 1;
      }
    });

    this.toolbarSelectFilterTargets.forEach(filterEl => {
      if (filterEl.value) {
        q[filterEl.name] = filterEl.value 
        filtersCount = filtersCount + 1;
      }
    });

    if (this.filtersToggleTargets.length > 0) {
      if (filtersCount > 0) {
        this.filtersToggleTarget.classList.remove('d-none');
        this.filtersToggleTarget.innerText = filtersCount;
      } else {
        this.filtersToggleTarget.classList.add('d-none');
      }
    }

    if (this.periodPicker) {
      q[`${this.periodColumnValue}_gteq`] = this.periodPicker.selectedDates[0] 
      q[`${this.periodColumnValue}_lteq`] = this.periodPicker.selectedDates[1] 
    }

    return {
      draw: d.draw,
      start: d.start,
      length: d.length,
      q: q
    };
  }

	connect(){
    if (this.periodColumnValue != '') {
      this.periodPicker = $(this.periodPickerTarget).flatpickr({
        altInput: true,
        altFormat: "d.m.Y",
        dateFormat: "Y-m-d",
        mode: "range",
        onChange: (selectedDates, dateStr, instance) => {
          this.search();
        },
      });
    }

    let dataColumns = this.columnTargets.map(column => ({ data: column.dataset.columnData }));
    let columnDefs = this.columnTargets.map(column => ({
      target: parseInt(column.dataset.columnIndex),
      orderable: column.dataset.columnOrderable == 'true',
      searchKey: column.dataset.searchKey
    }));
    let defaultOrderColumn = 1;

    this.columnSortings = {};
    this.columnTargets.forEach((column, index) => {
      this.columnSortings[column.dataset.columnData] = column.dataset.sortColumn

      if (column.dataset.defaultSorting) {
        defaultOrderColumn = index + 1;
      }
    });

    this.dataTable = $(this.tableTarget).DataTable({
      searchDelay: 500,
      processing: true,
      serverSide: true,
      pageLength: 25,
      lengthMenu: [ 25, 50, 100, 200, 500 ],
      stateSave: true,
      stateDuration: 0,
      stateSaveParams: (settings, data) => {
        if (this.periodPicker) {
          data.period = this.periodPicker.selectedDates.map((date) => { return moment(date).format('YYYY-MM-DD') })
        }

        if (data.filters == null) {
          data.filters = {}
        }
        this.filterTargets.forEach(filterEl => {
          if (filterEl.value) {
            data.filters[filterEl.name] = filterEl.value;
          }
        });
      },
      stateLoadParams: (settings, data) => {
        this.searchTarget.value = data.search.search;

        if (this.periodPicker) {
          if (data.period) {
            this.periodPicker.setDate(data.period, false);
          }
        }

        if (data.filters) {
          this.filterTargets.forEach(filterEl => {
            if (data.filters[filterEl.name]) {
              filterEl.value = data.filters[filterEl.name];
            }
          });
        }

      },
      order: [[defaultOrderColumn, 'desc']],
      select: {
        style: 'multi',
        selector: 'td:first-child input[type="checkbox"]',
        className: 'row-selected'
      },
      ajax: {
        url: this.ajaxUrlValue,
        data: d => { return this.transformAjaxData(d) }
      },
      columns: [
        { data: 'id' },
        ...dataColumns,
        { data: 'actions' }
      ],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          render: (data) => {
            return `
                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" value="${data}" data-data-table-target="checkbox" data-action="data-table#toggleToolbars" />
                            </div>`;
          }
        },
        ...columnDefs,
        {
          targets: -1,
          data: null,
          orderable: false,
          className: 'text-end'
        },
      ]
    });

    this.dataTable.on('draw', () => {
      //initToggleToolbar();
      this.toggleToolbars();
      //handleDeleteRows();
      refreshFsLightbox();

      let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
      let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
    });

    $(this.tableTarget).on('xhr.dt', (e, settings, json, xhr ) => {
      if (json?.footer && this.hasFooterTarget) {
        this.footerTarget.innerHTML = json.footer;
      }

      if (json?.summary) {
        const summaryEl = document.querySelector('#summary')
        if (summaryEl) {
          summaryEl.innerHTML = json.summary;
        }
      }
    });

    $(this.toolbarSelectFilterTargets).on('select2:select', (e) => {
      this.applyFilters();
    });

    $(this.toolbarSelectFilterTargets).on('select2:clear', (e) => {
      this.applyFilters();
    });

    console.log('connect')
  }


  disconnect() {
    if (this.dataTable) {
      this.dataTable.destroy()
      this.dataTable = undefined
    }

    if (this.periodPicker) {
      this.periodPicker.destroy()
      this.periodPicker = undefined
    }

    console.log('disconnect')
  }

  search() {
    if (this.searchTarget.value && this.searchTarget.value.length < 2) {
      return;
    }
    
    this.dataTable.search(this.searchTarget.value).draw();
  }

  clearPeriod() {
    if (this.periodPicker) {
      this.periodPicker.clear();
    }
  }

  applyFilters(ev) {
    this.dataTable.ajax.reload();
  }

  resetFilters() {
    this.filterTargets.forEach(filterEl => {
      filterEl.value = '';
    });
    this.dataTable.ajax.reload();
  }

  exportXlsx(ev) {
    ev.preventDefault();

    var queryString = $.param(this.dataTable.ajax.params());
    var separator = (this.exportUrlValue.indexOf('?') > -1) ? '&' : '?';

    this.downloaderTarget.setAttribute('src', `${this.exportUrlValue}${separator}${queryString}`);
  }

  exportSelectedXlsx(ev) {
    ev.preventDefault();

    var queryString = $.param({ q: { id_in: this.selectedIds } });
    var separator = (this.exportUrlValue.indexOf('?') > -1) ? '&' : '?';

    this.downloaderTarget.setAttribute('src', `${this.exportUrlValue}${separator}${queryString}`);
  }

  beforeStreamRender(event) {
    const fallbackToDefaultActions = event.detail.render;

    event.detail.render = (streamElement) => {
      if (streamElement.action == "reload") {
        this.dataTable.ajax.reload();
      } else {
        fallbackToDefaultActions(streamElement)
      }
    }
  }

  toggleToolbars() {
    // Detect checkboxes state & count
    let checkedState = false;
    let count = 0;

    // Count checked boxes
    this.checkboxTargets.forEach(c => {
      if (c.checked) {
        checkedState = true;
        count++;
      }
    });

    // Toggle toolbars
    if (checkedState) {
      this.selectedCountTarget.innerHTML = count;
      this.toolbarBaseTarget.classList.add('d-none');
      this.toolbarSelectedTarget.classList.remove('d-none');
    } else {
      this.toolbarBaseTarget.classList.remove('d-none');
      this.toolbarSelectedTarget.classList.add('d-none');
    }
  }

  delayedToggleToolbars() {
    setTimeout(() => { this.toggleToolbars() }, 50);
  }

  batchUpdate(event) {
    event.preventDefault();

    const csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(event.params.url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ q: { id_in: this.selectedIds }, attributes: event.params.attributes }) // body data type must match "Content-Type" header
    }).then(() => { this.applyFilters() })
  }

  batchDelete(event) {
    event.preventDefault();

    Swal.fire({
      text: "Are you sure you want to delete selected items?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: "No, cancel",
      customClass: {
        confirmButton: "btn fw-bold btn-danger",
        cancelButton: "btn fw-bold btn-active-light-primary"
      }
    }).then((result) => {
      if (result.value) {
        const csrfToken = document.querySelector("[name='csrf-token']").content

        

        fetch(this.batchDeleteUrlValue, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          },
          body: JSON.stringify({ q: { id_in: this.selectedIds } }) // body data type must match "Content-Type" header
        })
          .then(response => {
            this.dataTable.ajax.reload();
          })
      }
    });
  }

  get selectedIds() {
    let ids = [];

    this.checkboxTargets.forEach(c => {
      if (c.checked) {
        ids.push(c.value);
      }
    });

    return ids;
  }
}
