import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tagify"
export default class extends Controller {
  static values = {
		suggestionsUrl: String,
    maxTags: Number
  }

  connect() {
    this.tagify = new Tagify(this.element, {
      maxTags: this.maxTagsValue,
      whitelist: this.element.value.trim().split(/\s*,\s*/)
    });

    if (this.hasSuggestionsUrlValue) {
      this.tagify.on('focus', (e) => { this.onTagifyFocusBlur(e) })
    }
  }

  onTagifyFocusBlur(e) {
    this.tagify.whitelist = null; // reset current whitelist
    this.tagify.loading(true) // show the loader animation

    fetch(this.suggestionsUrlValue).then((response) => {
			response.json().then((data) => {
        this.tagify.settings.whitelist = data.concat(this.tagify.value) // add already-existing tags to the new whitelist array

        this.tagify
            .loading(false)
            // render the suggestions dropdown.
            .dropdown.show(e.detail.value);
      }).catch(err => this.tagify.dropdown.hide())
    }).catch(err => this.tagify.dropdown.hide())
  }
}
