import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    refreshFsLightbox();
  }

  beforeStreamRender(event) {
    const fallbackToDefaultActions = event.detail.render;

    event.detail.render = (streamElement) => {
      fallbackToDefaultActions(streamElement);
      refreshFsLightbox();
    }
  }
}
