import { Controller } from "@hotwired/stimulus"
//import $ from 'jquery';
//import Select2 from "select2"

export default class extends Controller {
	static targets = [ "document", "payment", "amount", "amountCurrency" ];

	connect() {
    if (this.documentTargets.length > 0) {
      $(this.documentTarget).select2({
        ajax: {
          url: '/documents',
          delay: 250,
          data: (params) => {
            return {
              q: {
                search_cont: params.term
              },
              page: params.page
            };
          }
        }
      });

      $(this.documentTarget).on('select2:select', (e) => {
        var data = e.params.data;
        console.log(data);

        if (data.price) {
          this.amountTarget.value = data.price;
          this.amountCurrencyTarget.value = data.price_currency;
        }
      });
    }

    if (this.paymentTargets.length > 0) {
      $(this.paymentTarget).select2({
        ajax: {
          url: '/payments',
          delay: 250,
          data: (params) => {
            return {
              q: {
                search_cont: params.term
              },
              page: params.page
            };
          }
        }
      });

      $(this.paymentTarget).on('select2:select', (e) => {
        var data = e.params.data;
        console.log(data);

        if (data.price) {
          this.amountTarget.value = data.price;
          this.amountCurrencyTarget.value = data.price_currency;
        }
      });
    }
	}
}
