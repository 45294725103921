import { Controller } from "@hotwired/stimulus"
//import $ from 'jquery';
//import Select2 from "select2"

export default class extends Controller {
	static targets = [ "gpsService", "gpsServiceUid" ];

	connect() {
		$(this.gpsServiceUidTarget).select2({
			ajax: {
				url: '/trucks/gps_uids',
				data: (params) => {
					return {
						term: params.term,
            gps_service_id: this.gpsService,
						page: params.page
					};
				}
			}
		});
	}

	get gpsService() {
		return this.gpsServiceTarget.value
	}
}
