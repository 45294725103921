import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stepper"
export default class extends Controller {
  connect() {
    let stepper = new KTStepper(this.element);

    // Handle navigation click
    stepper.on("kt.stepper.click", function (stepper) {
      stepper.goTo(stepper.getClickedStepIndex()); // go to clicked step
    });

    // Handle next step
    stepper.on("kt.stepper.next", function (stepper) {
      stepper.goNext(); // go next step
    });

    // Handle previous step
    stepper.on("kt.stepper.previous", function (stepper) {
      stepper.goPrevious(); // go previous step
    });
  }
}
