import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="truck-mileage"
export default class extends Controller {
  static targets = [ "truck", "datetime", "mileage" ]

  connect() {
    $(this.truckTarget).on('select2:select', () => { this.changed() })
  }

  changed() {
    const params = { at: moment(this.datetimeTarget.value).utc().format() };

    fetch(`/trucks/${this.truckTarget.value}/mileage.json?` + new URLSearchParams(params).toString()).then((response) => {
			response.json().then((data) => {
        this.mileageTarget.value = data.mileage;
      })
    })
  }
}
