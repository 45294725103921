import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
	static targets = [ "messageWrapper" ];
	static values = { anchor: String }

	connect() {
    window.hash = self.anchorValue;

    this.messageWrapperTargets.forEach(accordion => {
      // Set variables
      const header = accordion.querySelector('[data-kt-inbox-message="header"]');
      const previewText = accordion.querySelector('[data-kt-inbox-message="preview"]');
      const details = accordion.querySelector('[data-kt-inbox-message="details"]');
      const message = accordion.querySelector('[data-kt-inbox-message="message"]');

      // Init bootstrap collapse -- more info: https://getbootstrap.com/docs/5.1/components/collapse/#via-javascript
      const collapse = new bootstrap.Collapse(message, { toggle: false });

      // Handle header click action
      header.addEventListener('click', e => {
        // Return if KTMenu or buttons are clicked
        if (e.target.closest('[data-kt-menu-trigger="click"]') || e.target.closest('.btn')) {
          return;
        } else {
          previewText.classList.toggle('d-none');
          details.classList.toggle('d-none');
          collapse.toggle();
        }
      });
    });
  }
}
