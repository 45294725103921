import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = {
		newItemUrl: String,
		newItemParam: String,
		noResultsTitle: String 
	}

	connect() {
		let opts = {};

		if (this.newItemUrlValue) {
			opts.language = {
				noResults: () => {
					let search = $(this.element).data("select2").dropdown.$search.val();
					return $(`<a data-turbo-frame="modal" href="${this.newItemUrlValue}?search=${search}&auto_select_id=${this.element.id}">${this.noResultsTitleValue}</a>`);
				}
			}
		}

		$(this.element).select2(opts);
	}

	beforeStreamRender(event) {
		const fallbackToDefaultActions = event.detail.render;
	
		event.detail.render = (streamElement) => {
		  if (streamElement.action == "select_just_created") {
			let elementId = streamElement.getAttribute('select_id');
			let option = new Option(streamElement.getAttribute('title'), streamElement.getAttribute('value'), true, true);
			$('#' + elementId).append(option).trigger('change');
		  } else {
			fallbackToDefaultActions(streamElement)
		  }
		}
	  }
}
