import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "table", "search" ];

	connect(){
    this.dataTable = $(this.tableTarget).DataTable({
      pageLength: 25
    });
  }

  disconnect() {
    this.dataTable.destroy()
    this.dataTable = undefined
  }

  beforeStreamRender(event) {
    const fallbackToDefaultActions = event.detail.render;

    event.detail.render = (streamElement) => {
      fallbackToDefaultActions(streamElement);
    }
  }

  search() {
    this.dataTable.search(this.searchTarget.value).draw();
  }
}
