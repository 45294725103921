import { Controller } from "@hotwired/stimulus"
import consumer from '../channels/consumer';

export default class extends Controller {
	static targets = [ "placeholder", "search", "truck" ]
	static values = { 
		tripUrl: String, 
		userSignedIn: Boolean 
	}

	connect(){
		if (typeof (google) != "undefined"){
			this.initializeMap()
		}
	}

	disconnect(){
		if (this.channel) {
			this.channel.unsubscribe();
		}
		this.stopRefreshing();
	}

	searchTrucks() {
		const searchQuery = this.searchTarget.value;

		this.truckTargets.forEach((truck) => {
			let marker = this._markers[truck.dataset.truckId];

			if (searchQuery.length == 0 || truck.textContent.includes(searchQuery)) {
				truck.classList.remove('d-none');
				if (marker) {
					marker.setVisible(true);
				}
			} else {
				truck.classList.add('d-none');
				if (marker) {
					marker.setVisible(false);
				}
			}
		})
	}

	selectTruck({params}) {
		this.openTruckInfoWindow(params.id, this._markers[params.id]);
	}

	initializeMap() {
		this.map()
		this.markers()
		console.log('init google maps')
	}

	map() {
		if(this._map == undefined) {
			this._map = new google.maps.Map(this.placeholderTarget, {
				center: new google.maps.LatLng(
					48.6194332, 22.2446183,
					22.2446183
				),
				zoom: 6
			})

		}
		return this._map
	}

	infoWindow() {
		if(this._infoWindow == undefined) {
			this._infoWindow = new google.maps.InfoWindow();
		}
		return this._infoWindow;
	}

	markers() {
		if(this._markers == undefined) {
			this._markers = {}
		}
		if(this._markersPopups == undefined) {
			this._markersPopups = {}
		}

		if(this.channel == undefined && this.userSignedInValue) {
			this.subscribeTruckChannel();
		}

		this.truckMarkers()
		//this.startRefreshing()
	}


	truckMarkers() {
		if (this.hasTripUrlValue) {
			this.tripMarkers()
		} else {
			this.truckAllMarkers()
		}
	}

	buildMarkerIcon(truck) {
		return {
			path: ((truck.gps_speed > 0.0 && truck.gps_direction !== undefined) ? google.maps.SymbolPath.FORWARD_CLOSED_ARROW : google.maps.SymbolPath.CIRCLE),
			fillColor: (truck.gps_ignition ? 'red' : "blue"),
			fillOpacity: 0.6,
			strokeWeight: 0,
			rotation: truck.gps_direction,
			scale: ((truck.gps_speed > 0.0 && truck.gps_direction !== undefined) ? 7 : 17)
		};
	}

	truckAllMarkers() {
		fetch('/trucks/map.json').then((response) => {
			response.json().then((data) => {

				data.forEach((truck) => {
					this._markersPopups[truck.id] = truck.popup_content;

					let marker = this._markers[truck.id]
					if (marker == undefined) {
						marker = new google.maps.Marker({
							map: this.map(),
							anchorPoint: new google.maps.Point(0,0),
							title: truck.title,
							label: { color: 'black', fontWeight: 'bold', fontSize: '10px', text: truck.title, className: 'truck-marker-label' }
						})
						this._markers[truck.id] = marker
					}
					let mapLocation = {
						lat: truck.gps_latitude,
						lng: truck.gps_longitude
					}

					let markerSvgIcon = this.buildMarkerIcon(truck);

					marker.setPosition(mapLocation);
					marker.setIcon(markerSvgIcon);
					marker.setVisible(true)

					marker.addListener("click", () => {
						this.openTruckInfoWindow(truck.id, marker);
					});
				});
			});
		});
	}

	openTruckInfoWindow(truckId, marker) {
		const infoWindow = this.infoWindow();

		this.activeTruckId = truckId;

		infoWindow.close();
		infoWindow.setContent(this._markersPopups[truckId]);
		infoWindow.open(this.map(), marker);
	}

	tripMarkers() {
    const csrfToken = document.querySelector("[name='csrf-token']").content
		const directionsService = new google.maps.DirectionsService();
		const directionsRenderer = new google.maps.DirectionsRenderer();

		directionsRenderer.setMap(this.map());

		fetch(this.tripUrlValue).then((response) => {
			response.json().then((data) => {
        const trip = data;

				const request = {
					origin: data.start_address,
					destination: data.end_address,
					waypoints: data.waypoints,
					travelMode: 'DRIVING'
				};
        directionsService.route(request, (result, status) => {
					if (status == 'OK') {
						directionsRenderer.setDirections(result);
          }
        });

				data.trucks.forEach((truck) => {
					let marker = this._markers[truck.id]
					if (marker == undefined) {
						marker = new google.maps.Marker({
							map: this.map(),
							anchorPoint: new google.maps.Point(0,0),
							title: truck.title,
							label: { color: 'black', fontWeight: 'bold', fontSize: '14px', text: truck.title }
						})
						this._markers[truck.id] = marker
					}
					let mapLocation = {
						lat: truck.gps_latitude,
						lng: truck.gps_longitude
					}

					let markerSvgIcon = this.buildMarkerIcon(truck)

					marker.setPosition(mapLocation)
					marker.setIcon(markerSvgIcon)
					marker.setVisible(true)
				});
			});
		});
	}

	subscribeTruckChannel() {
		this.channel = consumer.subscriptions.create('TruckChannel', {
			received: this._cableReceived.bind(this),

			refreshGps() {
				this.perform("refresh_gps", {})
			},
		});
	}

	_cableReceived(truck) {
		const infoWindow = this.infoWindow();
		let marker = this._markers[truck.id];

		this._markersPopups[truck.id] = truck.popup_content;

		if (marker) {
			let mapLocation = {
				lat: truck.gps_latitude,
				lng: truck.gps_longitude
			}

			let markerSvgIcon = this.buildMarkerIcon(truck);

			marker.setPosition(mapLocation);
			marker.setIcon(markerSvgIcon);

			if (this.activeTruckId == truck.id) {
				infoWindow.setContent(truck.popup_content);
			}

			console.log(`Updating truck ${marker.title} to ${truck.gps_latitude},${truck.gps_longitude}`)
		}
	}

	//startRefreshing() {
	//	this.refreshTimer = setInterval(() => {
	//		this.channel.refreshGps()
	//	}, 10000)
	//}

	stopRefreshing() {
		if (this.refreshTimer) {
			clearInterval(this.refreshTimer)
		}
	}
}
