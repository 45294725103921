import { Application } from "@hotwired/stimulus"
import NestedForm from "stimulus-rails-nested-form"
import PlacesAutocomplete from 'stimulus-places-autocomplete'
import Sortable from 'stimulus-sortable'
import Clipboard from 'stimulus-clipboard'
import Chart from 'stimulus-chartjs'
import Reveal from 'stimulus-reveal-controller'

const application = Application.start()

application.register("nested-form", NestedForm)
application.register('places', PlacesAutocomplete)
application.register('sortable', Sortable)
application.register('clipboard', Clipboard)
application.register('chart', Chart)
application.register('reveal', Reveal)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application


document.addEventListener("turbo:load", function() {
  console.log('turbo:load');
});


document.addEventListener("turbo:before-cache", function() {
  var elements = Array.prototype.slice.call(document.querySelectorAll("[data-kt-initialized]"));

  if (elements && elements.length > 0) {
    elements.forEach((element) => {
      console.log('cleaning kt-initialized')
      console.log(element);
      element.setAttribute("data-kt-initialized", null);
    });
  }

  console.log('turbo:before-cache');
});


document.addEventListener("select2:open", function() {
   var elements = document.querySelectorAll('.select2-container--open .select2-search__field');
      if (elements.length > 0) {
        elements[elements.length - 1].focus();
      }  
});

flatpickr.localize(flatpickr.l10ns.uk);

export { application }
