// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import { StreamActions } from "@hotwired/turbo"
import "./controllers"
import * as bootstrap from "bootstrap"

StreamActions.reload = function() {}

StreamActions.toast = function() {
  const message = this.getAttribute("message")
  const type = this.getAttribute("type")

  toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toastr-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  };

  if (type == 'success') {
    toastr.success(message);
  } else if (type == 'error') {
    toastr.error(message);
  }
}

StreamActions.redirect_to = function() {
  window.location.href = this.getAttribute("url");
}
