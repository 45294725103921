import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="truck-report"
export default class extends Controller {
  static targets = [ "periodPicker", "display" ];
  static values = { 
    truckId: Number, 
    start: String, 
    end: String 
  };

  connect(){
    let cb = (start, end) => {
      var current = moment();

      if (this.hasDisplayTarget) {
          if ( current.isSame(start, "day") && current.isSame(end, "day") ) {
              this.displayTarget.innerHTML = start.format('D MMM YYYY');
          } else {
              this.displayTarget.innerHTML = start.format('D MMM YYYY') + ' - ' + end.format('D MMM YYYY');
          }                    
      }

      let search = `?start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}`;

      if (location.search != search) {
        Turbo.visit(search, { frame: 'truck_report' })
      }
    }

    let start = moment(this.startValue);
    let end = moment(this.endValue);

    $(this.periodPickerTarget).daterangepicker({
      startDate: start,
      endDate: end,
      opens: 'left',
      ranges: {
        'Сьогодні': [moment(), moment()],
        'Вчора': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Остані 7 днів': [moment().subtract(6, 'days'), moment()],
        'Остані 30 днів': [moment().subtract(29, 'days'), moment()],
        'Цей місяць': [moment().startOf('month'), moment().endOf('month')],
        'Минулий місяць': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      locale: {
        "firstDay": 1,
        "customRangeLabel": "Обрати період"
      }
    }, cb);

    cb(start, end);
  }
}
