import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "yearPicker" ];

	connect(){
    $(this.yearPickerTarget).on('select2:select', (e) => {
      Turbo.visit(`?year=${this.year}`, { frame: 'report' })
    });
  }

	get year() {
		return this.yearPickerTarget.value;
	}
}
