import { Controller } from "@hotwired/stimulus"
//import $ from 'jquery';
//import Select2 from "select2"

export default class extends Controller {
	static targets = [ "ownerType", "owner" ];

  connect() {
		$(this.ownerTarget).select2({
			ajax: {
				url: (params) => {
          if (this.ownerType == 'Driver') {
            return '/drivers.json';
          } else if (this.ownerType == 'Truck') {
            return '/trucks.json';
          } else if (this.ownerType == 'Trailer') {
            return '/trailers.json';
          }
        },
        data: (params) => {
          return {
            'q[search_cont]': params.term,
            page: params.page
          };
				}
			}
    });
	}

	get ownerType() {
		return this.ownerTypeTarget.value
	}
}
