import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "fieldType", "options" ];

	fieldTypeChanged() {
		const element = this.fieldTypeTarget

		console.log(`Hello, ${this.fieldType}!`)

		if (this.fieldType == 'select_input') {
			this.optionsTargets.forEach((e) => e.classList.remove('d-none'))
		} else {
			this.optionsTargets.forEach((e) => e.classList.add('d-none'))
		}
	}

	get fieldType() {
		return this.fieldTypeTarget.value
	}
}
