import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = { 
		enableTime: { type: Boolean, default: false } 
	};

	connect() {
    	$(this.element).flatpickr({
			allowInput: true,
			enableTime: this.enableTimeValue
		});
	}
}
