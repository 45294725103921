import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
	static targets = [ "header", "preview", "details", "message" ];

	connect() {
    // Init bootstrap collapse -- more info: https://getbootstrap.com/docs/5.1/components/collapse/#via-javascript
    this.collapse = new bootstrap.Collapse(this.messageTarget, { toggle: false });
  }

  toggle(e) {
    // Return if KTMenu or buttons are clicked
    if (e.target.closest('[data-kt-menu-trigger="click"]') || e.target.closest('.btn')) {
      return;
    } else {
      this.previewTarget.classList.toggle('d-none');
      this.detailsTarget.classList.toggle('d-none');
      this.collapse.toggle();
    }
  }
}
