import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="waybill-form"
export default class extends Controller {
  static targets = [ 
    "truck", 
    "departureAt", 
    "departureOdometer",
    "departureFuelLevel",
    "arrivalAt", 
    "arrivalOdometer",
    "arrivalFuelLevel"
   ];

  connect() {
    $(this.truckTarget).on('select2:select', () => { this.truckChanged() })
  }

  truckChanged() {
    if (this.departureAt) {
      this.departureChanged()
    }
    
    if (this.arrivalAt) {
      this.arrivalChanged()
    }
  }

  departureChanged() {
    const params = { received_at: this.departureAt, kind: 'departure' }

    fetch(`/trucks/${this.truck}/tracking_point.json?` + new URLSearchParams(params).toString()).then((response) => {
			response.json().then((data) => {
        this.departureOdometerTarget.value = data.mileage;
        this.departureFuelLevelTarget.value = data.total_fuel_level
      })
    })
  }

  arrivalChanged() {
    const params = { received_at: this.arrivalAt, kind: 'arrival' }

    fetch(`/trucks/${this.truck}/tracking_point.json?` + new URLSearchParams(params).toString()).then((response) => {
			response.json().then((data) => {
        this.arrivalOdometerTarget.value = data.mileage;
        this.arrivalFuelLevelTarget.value = data.total_fuel_level
      })
    })
  }

  get truck() {
		return this.truckTarget.value
	}

  get departureAt() {
    return this.departureAtTarget.value
  }

  get arrivalAt() {
    return this.arrivalAtTarget.value
  }
}
