import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "truck" ];

  connect() {

    $(this.truckTargets).on('select2:select', (e) => {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.truckTarget.dispatchEvent(event);
    });
  }
}

