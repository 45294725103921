import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="billing-subscription"
export default class extends Controller {
	static targets = [ "price", "yearlyPrice", "months", "users", "amount" ];

  connect() {
    this.monthsChanged();
  }

  monthsChanged() {
    this.amountTarget.value = this.amount;
  }

  get amount() {
    if (this.monthsTarget.value == 12) {
      return this.usersTarget.value * this.yearlyPriceTarget.value;
    } else {
      return this.usersTarget.value * this.priceTarget.value * this.monthsTarget.value;
    }
  }
}
