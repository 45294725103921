import { Controller } from "@hotwired/stimulus"

// Handle CC and BCC toggle
const handleCCandBCC = (el) => {
  // Get elements
  const ccElement = el.querySelector('[data-kt-inbox-form="cc"]');
  const ccButton = el.querySelector('[data-kt-inbox-form="cc_button"]');
  const ccClose = el.querySelector('[data-kt-inbox-form="cc_close"]');
  const bccElement = el.querySelector('[data-kt-inbox-form="bcc"]');
  const bccButton = el.querySelector('[data-kt-inbox-form="bcc_button"]');
  const bccClose = el.querySelector('[data-kt-inbox-form="bcc_close"]');

  // Handle CC button click
  ccButton.addEventListener('click', e => {
    e.preventDefault();

    ccElement.classList.remove('d-none');
    ccElement.classList.add('d-flex');
  });

  // Handle CC close button click
  ccClose.addEventListener('click', e => {
    e.preventDefault();

    ccElement.classList.add('d-none');
    ccElement.classList.remove('d-flex');
  });

  // Handle BCC button click
  bccButton.addEventListener('click', e => {
    e.preventDefault();

    bccElement.classList.remove('d-none');
    bccElement.classList.add('d-flex');
  });

  // Handle CC close button click
  bccClose.addEventListener('click', e => {
    e.preventDefault();

    bccElement.classList.add('d-none');
    bccElement.classList.remove('d-flex');
  });
}


// Init tagify 
const initTagify = (el) => {
  var inputElm = el;

  function tagTemplate(tagData) {
    return `
                <tag title="${(tagData.title || tagData.email)}"
                        contenteditable='false'
                        spellcheck='false'
                        tabIndex="-1"
                        class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ""}"
                        ${this.getAttributes(tagData)}>
                    <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
                    <div class="d-flex align-items-center">
                        <div class='tagify__tag__avatar-wrap ps-0'>
                            <img onerror="this.style.visibility='hidden'" class="rounded-circle w-25px me-2" src="${hostUrl}media/${tagData.avatar}">
                        </div>
                        <span class='tagify__tag-text'>${tagData.name}</span>
                    </div>
                </tag>
            `
  }

  function suggestionItemTemplate(tagData) {
    return `
                <div ${this.getAttributes(tagData)}
                    class='tagify__dropdown__item d-flex align-items-center ${tagData.class ? tagData.class : ""}'
                    tabindex="0"
                    role="option">

                    ${tagData.avatar ? `
                            <div class='tagify__dropdown__item__avatar-wrap me-2'>
                                <img onerror="this.style.visibility='hidden'"  class="rounded-circle w-50px me-2" src="${hostUrl}media/${tagData.avatar}">
                            </div>` : ''
                    }

                    <div class="d-flex flex-column">
                        <strong>${tagData.name}</strong>
                        <span>${tagData.email}</span>
                    </div>
                </div>
            `
  }

  // initialize Tagify on the above input node reference
  var tagify = new Tagify(inputElm, {
    tagTextProp: 'name', // very important since a custom template is used with this property as text. allows typing a "value" or a "name" to match input with whitelist
    //enforceWhitelist: true,
    skipInvalid: true, // do not remporarily add invalid tags
    dropdown: {
      closeOnSelect: false,
      enabled: 0,
      classname: 'users-list',
      searchKeys: ['name', 'email']  // very important to set by which keys to search for suggesttions when typing
    },
    templates: {
      tag: tagTemplate,
      dropdownItem: suggestionItemTemplate
    },
    //whitelist: usersList
  })

  //tagify.on('dropdown:show dropdown:updated', onDropdownShow)
  tagify.on('dropdown:select', onSelectSuggestion)

  var addAllSuggestionsElm;

  function onDropdownShow(e) {
    var dropdownContentElm = e.detail.tagify.DOM.dropdown.content;

    if (tagify.suggestedListItems.length > 1) {
      addAllSuggestionsElm = getAddAllSuggestionsElm();

      // insert "addAllSuggestionsElm" as the first element in the suggestions list
      dropdownContentElm.insertBefore(addAllSuggestionsElm, dropdownContentElm.firstChild)
    }
  }

  function onSelectSuggestion(e) {
    if (e.detail.elm == addAllSuggestionsElm)
      tagify.dropdown.selectAll.call(tagify);
  }

  // create a "add all" custom suggestion element every time the dropdown changes
  function getAddAllSuggestionsElm() {
    // suggestions items should be based on "dropdownItem" template
    return tagify.parseTemplate('dropdownItem', [{
      class: "addAll",
      name: "Add all",
      email: tagify.settings.whitelist.reduce(function (remainingSuggestions, item) {
        return tagify.isTagDuplicate(item.value) ? remainingSuggestions : remainingSuggestions + 1
      }, 0) + " Members"
    }]
    )
  }
}


// Init dropzone
const initDropzone = (el) => {
  // set the dropzone container id
  const id = '[data-kt-inbox-form="dropzone"]';
  const dropzone = el.querySelector(id);
  const uploadButton = el.querySelector('[data-kt-inbox-form="dropzone_upload"]');

  // set the preview element template
  var previewNode = dropzone.querySelector(".dropzone-item");
  previewNode.id = "";
  var previewTemplate = previewNode.parentNode.innerHTML;
  previewNode.parentNode.removeChild(previewNode);

  var myDropzone = new Dropzone(id, { // Make the whole body a dropzone
    url: "https://preview.keenthemes.com/api/dropzone/void.php", // Set the url for your upload script location
    parallelUploads: 20,
    maxFilesize: 1, // Max filesize in MB
    previewTemplate: previewTemplate,
    previewsContainer: id + " .dropzone-items", // Define the container to display the previews
    clickable: uploadButton // Define the element that should be used as click trigger to select files.
  });


  myDropzone.on("addedfile", function (file) {
    // Hookup the start button
    const dropzoneItems = dropzone.querySelectorAll('.dropzone-item');
    dropzoneItems.forEach(dropzoneItem => {
      dropzoneItem.style.display = '';
    });
  });

  // Update the total progress bar
  myDropzone.on("totaluploadprogress", function (progress) {
    const progressBars = dropzone.querySelectorAll('.progress-bar');
    progressBars.forEach(progressBar => {
      progressBar.style.width = progress + "%";
    });
  });

  myDropzone.on("sending", function (file) {
    // Show the total progress bar when upload starts
    const progressBars = dropzone.querySelectorAll('.progress-bar');
    progressBars.forEach(progressBar => {
      progressBar.style.opacity = "1";
    });
  });

  // Hide the total progress bar when nothing"s uploading anymore
  myDropzone.on("complete", function (progress) {
    const progressBars = dropzone.querySelectorAll('.dz-complete');

    setTimeout(function () {
      progressBars.forEach(progressBar => {
        progressBar.querySelector('.progress-bar').style.opacity = "0";
        progressBar.querySelector('.progress').style.opacity = "0";
      });
    }, 300);
  });
}


export default class extends Controller {
  static targets = [ "form", "tagify", "body", "send", "attachDocuments", "attachment", "truck", "driver", "trailer" ];

  connect() {
    // Set variables

    // Handle CC and BCC
    handleCCandBCC(this.formTarget);

    // Init tagify
    this.tagifyTargets.forEach(tagify => {
      initTagify(tagify);
    });

    // Init quill editor
    this.initQuill(this.formTarget);

    // Init dropzone
    initDropzone(this.formTarget);

    this.replaceVariables = {}

    $(this.truckTarget).on('select2:select', (e) => {
      var data = e.params.data;

      this.replaceVariables.truck = data.text;

      if (data.driver) {
        var option = new Option(data.driver.title, data.driver.id, true, true);
        this.replaceVariables.driver = data.driver.title;
        this.mergeReplaceVariables('driver', data.driver.named_custom_fields);

        $(this.driverTarget).append(option).trigger('change');
      } else {
        $(this.driverTarget).val(null).trigger('change');
      }

      if (data.trailer) {
        var option = new Option(data.trailer.title, data.trailer.id, true, true);
        this.replaceVariables.trailer = data.trailer.title;
        $(this.trailerTarget).append(option).trigger('change');
      } else {
        $(this.trailerTarget).val(null).trigger('change');
      }
    });

    $(this.trailerTarget).on('select2:select', (e) => {
      var data = e.params.data;
      this.replaceVariables.trailer = data.text;
    });

    $(this.driverTarget).on('select2:select', (e) => {
      var data = e.params.data;
      this.replaceVariables.driver = data.text;
      this.mergeReplaceVariables('driver', data.named_custom_fields);
    });
  }

  mergeReplaceVariables(base, named_custom_fields) {
    Object.keys(named_custom_fields).forEach(key=>{
      this.replaceVariables[`${base}_${key}`] = named_custom_fields[key];
    });
  }

  // Handle submit form
  handleSubmit(event) {
    event.preventDefault();

    this.bodyTarget.value = this.quill.root.innerHTML;
    this.formTarget.requestSubmit();
  }

  // Init quill editor 
  initQuill(el) {
    this.quill = new Quill('#kt_inbox_form_editor', {
      modules: {
        toolbar: [
          [{
            header: [1, 2, false]
          }],
          ['bold', 'italic', 'underline'],
          ['image', 'code-block']
        ]
      },
      placeholder: 'Type your text here...',
      theme: 'snow' // or 'bubble'
    });

    // Customize editor
    const toolbar = el.querySelector('.ql-toolbar');

    if (toolbar) {
      const classes = ['px-5', 'border-top-0', 'border-start-0', 'border-end-0'];
      toolbar.classList.add(...classes);
    }
  }

  attachDocumentsChanged() {
    this.attachmentTargets.forEach(el => {
      if (el.classList.contains('d-flex')) {
        el.classList.remove('d-flex');
        el.classList.add('d-none');

        this.attachDocumentsTarget.value = false
      } else {
        el.classList.remove('d-none');
        el.classList.add('d-flex');

        this.attachDocumentsTarget.value = true
      }
    })
  }

  copySnippet({params}) {
    let interpolatedContent = params.content;

    for (const key in this.replaceVariables) {
      if (this.replaceVariables.hasOwnProperty(key)) {
        interpolatedContent = interpolatedContent.replace("{$" + key + "}", this.replaceVariables[key])
      }
    }

    //this.quill.setText(interpolatedContent);
    this.quill.root.innerHTML = interpolatedContent;
  }
}
