import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "chart" ];

  connect() {
    this.initChart()
  }

  init() {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    this.root = am5.Root.new(this.chartTarget);
    let root = this.root

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    var colors = chart.get("colors");
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "country",
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 30,
        }),
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      paddingTop: 10,
      fontWeight: "400",
      fontSize: 13,
      fill: am5.color(KTUtil.getCssVariableValue('--bs-gray-500'))
    });

    xAxis.get("renderer").grid.template.setAll({
      disabled: true,
      strokeOpacity: 0
    });


    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      paddingLeft: 10,
      fontWeight: "400",
      fontSize: 13,
      fill: am5.color(KTUtil.getCssVariableValue('--bs-gray-500'))
    });

    yAxis.get("renderer").grid.template.setAll({
      stroke: am5.color(KTUtil.getCssVariableValue('--bs-gray-300')),
      strokeWidth: 1,
      strokeOpacity: 1,
      strokeDasharray: [3]
    });


    var paretoAxisRenderer = am5xy.AxisRendererY.new(root, {
      opposite: true,
    });

    var totalAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: paretoAxisRenderer,
      })
    );

    totalAxis.get("renderer").labels.template.setAll({
      fontWeight: "400",
      fontSize: 13,
      fill: am5.color(KTUtil.getCssVariableValue('--bs-gray-500'))
    });

    paretoAxisRenderer.grid.template.set("forceHidden", true);
    totalAxis.set("numberFormat", "#'UAH");


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "visits",
        categoryXField: "country",
      })
    );

    series.columns.template.setAll({
      tooltipText: "{categoryX}: {valueY}",
      tooltipY: 0,
      strokeOpacity: 0,
      cornerRadiusTL: 6,
      cornerRadiusTR: 6,
    });

    series.columns.template.adapters.add(
      "fill",
      function (fill, target) {
        return chart
          .get("colors")
          .getIndex(series.dataItems.indexOf(target.dataItem));
      }
    );

    // pareto series
    var totalSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: totalAxis,
        valueYField: "total_price",
        categoryXField: "country",
        stroke: am5.color(KTUtil.getCssVariableValue('--bs-dark')),
        maskBullets: false,
      })
    );

    totalSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: am5.color(KTUtil.getCssVariableValue('--bs-primary')),
          stroke: am5.color(KTUtil.getCssVariableValue('--bs-dark'))
        }),
      });
    });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear();
    chart.appear(1000, 100);

    fetch(`/dashboard/countries.json`).then(response => {
      response.json().then(data => {
        xAxis.data.setAll(data);
        series.data.setAll(data);
        totalSeries.data.setAll(data);
      })
    })
  }

  initChart() {
    // Check if amchart library is included
    if (typeof am5 === "undefined") {
      return;
    }

    am5.ready(() => {
      this.init();
    });

    // Update chart on theme mode change
    KTThemeMode.on("kt.thememode.change", () => {
      // Destroy chart
      this.root.dispose();

      // Reinit chart
      this.init();
    });
  }
}
