import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "modelType", "targetFieldTitle", "targetAssociation" ];

  connect() {
    $(this.targetFieldTitleTarget).select2({
			ajax: {
				url: '/settings/custom_triggers/custom_fields.json',
        data: (params) => {
          return {
            'q[title_cont]': params.term,
            'q[with_model_type]': (this.targetAssociation || this.modelType)
          };
				}
			}
    });

		$(this.targetAssociationTarget).select2({
			ajax: {
				url: '/settings/custom_triggers/custom_fields.json',
        data: (params) => {
          return {
            'q[field_type_eq]': 'association_input',
            'q[model_type_eq]': this.modelType
          };
				}
			}
    });
	}

	get modelType() {
		return this.modelTypeTarget.value
	}

	get targetAssociation() {
		return this.targetAssociationTarget.value
	}
}
