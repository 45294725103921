import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "jkanban", "country", "truck" ]

  loadBoards() {
    fetch('/planning/directions.json').then((response) => {
			response.json().then((data) => {
        this.boards = data;
        this.kanban.addBoards(data)
      })
    })
  }

  resetBoards() {
    this.boards.forEach((board) => this.kanban.removeBoard(board.id) )
  }

	connect(){
    this.kanban = new jKanban({
      element: '#jkanban-directions',
      gutter: '0',
      widthBoard: '250px',
      click: (el) => {
        Turbo.visit(`/trucks/${el.getAttribute('data-eid')}/planning`, { frame: 'modal' });
      },
      dropEl: (el, target, source, sibling) => {
        console.log(target.parentElement.getAttribute('data-id'));
        console.log(el, target, source, sibling)

        const csrfToken = document.querySelector("[name='csrf-token']").content

        let target_direction_id = target.parentElement.getAttribute('data-id');

        fetch(`/trucks/${el.getAttribute('data-eid')}`, {
          method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          },
          body: JSON.stringify({ planning_direction_id: target_direction_id }) // body data type must match "Content-Type" header
        })
          .then(response => {
            console.log(response)
          })
      },
      dragendBoard: (el) => {
        const csrfToken = document.querySelector("[name='csrf-token']").content

        fetch(`/planning/directions/${el.getAttribute('data-id')}`, {
          method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          },
          body: JSON.stringify({ planning_direction: { position: el.getAttribute('data-order') } }) // body data type must match "Content-Type" header
        })
          .then(response => {
            console.log(response)
          })

      }
    });

    this.loadBoards()
  }

  disconnect(){
    this.kanban = null
  }

  toggleCountry(event) {
    let countryEl = event.currentTarget;
    let country = countryEl.dataset.country;

    countryEl.classList.toggle('active');

    let isActive = countryEl.classList.contains('active');

    this.countryTargets.forEach((countryEl) => {
      if (isActive) {
        if (countryEl.dataset.country == country) {
          countryEl.classList.remove('opacity-50');
        } else {
          countryEl.classList.add('opacity-50');
        }
      } else {
        countryEl.classList.remove('opacity-50');
      }
    });

    this.truckTargets.forEach((itemEl) => {
      if (isActive) {
        if (itemEl.dataset.target_country == country) {
          itemEl.classList.remove('d-none');
        } else {
          itemEl.classList.add('d-none');
        }
      } else {
        itemEl.classList.remove('d-none');
      }
    });
  }

  beforeStreamRender(event) {
    const fallbackToDefaultActions = event.detail.render;

    event.detail.render = (streamElement) => {
      if (streamElement.action == "reload") {
        this.resetBoards()
        this.loadBoards()
      } else {
        fallbackToDefaultActions(streamElement)
      }
    }
  }

}
