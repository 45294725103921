import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="event-form"
export default class extends Controller {
	static targets = [ "truck", "mileage" ];

  connect() {
    $(this.truckTarget).select2({
			ajax: {
				url: '/trucks.json',
        delay: 250,
				data: (params) => {
					return {
						q: {
							search_cont: params.term
						},
						page: params.page
					};
				}
			}
		});

    $(this.truckTarget).on('select2:select', (e) => {
      var data = e.params.data;

      if (data.mileage) {
        $(this.mileageTarget).val(data.mileage);
      }
    });
  }
}
