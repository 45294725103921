import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "periodPicker" ];

	connect(){
    if (this.periodColumnValue != '') {
      this.periodPicker = $(this.periodPickerTarget).flatpickr({
        altInput: true,
        altFormat: "d.m.Y",
        dateFormat: "Y-m-d",
        mode: "range",
        onChange: (selectedDates, dateStr, instance) => {
          console.log(selectedDates);
          let path = "/fuel/report/transactions?";
          if (selectedDates[0]) {
            path += `q[performed_at_gteq]=${moment(selectedDates[0]).format('YYYY-MM-DD')}`
          }

          if (selectedDates[1]) {
            path += `&q[performed_at_lteq]=${moment(selectedDates[1]).format('YYYY-MM-DD')}`
          }

          Turbo.visit(path, { frame: 'report' })
        },
      });
    }
  }
}
