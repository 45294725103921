import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = {
    attributeName: { type: String, default: 'is_enabled' },
    url: String,
    resourceName: String
  }

	connect() {
  }

  toggle(e) {
    const csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(this.urlValue, {
      method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ [this.resourceNameValue]: { [this.attributeNameValue]: e.target.checked } }) // body data type must match "Content-Type" header
    })
      .then(response => {
        console.log(response)
      })
  }
}
