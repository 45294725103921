import { Controller } from "@hotwired/stimulus"
//import $ from 'jquery';
//import Select2 from "select2"

export default class extends Controller {
	static targets = [ "code", "fullTitle", "title", "address", "director" ];

  connect() {
    if (this.code && this.fullTitleTarget.value == '') {
      this.codeChanged();
    }
  }

  codeChanged() {
    fetch(`/clients/by_code?code=${this.code}`).then(response => {
      response.json().then(data => {
        if (data.address) {
          $(this.addressTarget).val(data.address)
        }

        if (data.director) {
          $(this.directorTarget).val(data.director)
        }

        if (data.full_title) {
          $(this.fullTitleTarget).val(data.full_title)
        }

        if (data.title) {
          $(this.titleTarget).val(data.title)
        } else if (data.full_title) {
          $(this.titleTarget).val(data.full_title)
        }
      })
    })
  }

  get code() {
    return this.codeTarget.value
  }
}
