import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="time-count-down"
export default class extends Controller {
  static values = { 
		expiring: String
	}
  
  connect() {
    this.format();

    this.timer = setInterval(() => {
      this.format();
    }, 1000)
  }

  format() {
    let diff = moment(this.expiringValue).diff(moment());
    if (diff > 0) {
      this.element.innerHTML = moment.utc(diff).format("mm:ss");
    } else {
      this.element.innerHTML = '-'
    }
  }

  disconnect() {
    if (this.timer) {
			clearInterval(this.timer)
		}
  }
}
